import React from "react"
import styled from 'styled-components'
import { navigate } from 'gatsby-link'
import { useQuery } from 'react-query'
import {
  Buttons, GreenBarSection, HeaderBanner, LightBlueSection,
  Text, WhiteSection, Flex, Vis, ButtonLinkOutline,
  ButtonGreen,
} from '../components/common'
import Layout from "../components/layout"
import MobileNumberField from '../components/MobileInput'
import { navTo } from '../utils/navigation'
import InfoIconSvg from '../images/Info_Dark_18.svg'
import api from '../utils/api'
import GlobalStoreContext from "../components/GlobalContext"
import { FREE_WEB_APP_ENABLED } from "../utils/featureStatus"

const InfoIcon = styled.img`
  height:20px;
  width:20px;
  border-radius:10px;
  margin: auto; 
`
const ErrorBootstrapSection = styled.div`
  margin: 20px 18px;
`

const BodyWrapper = styled(Flex)`
`

const Footer = styled(Flex)`
`

const mobileRegexPattern = /^((04\d{8}))$/


const ButtonDelay = (navigateFn) => {
  setTimeout(() => navigateFn(), 300)
}

const checkAccess = (isInSession, isPro, featureStatus) => {
  const freeWebAppFlag = ((featureStatus || []).find(feature => FREE_WEB_APP_ENABLED === feature.name) || {}).flag;
  const shouldRedirect = (!isInSession || (isInSession && !isPro)) && !freeWebAppFlag;

  if (shouldRedirect) {
    navigate('/introduction-1')
  }
};

const CheckFreeUserAccess = (featureStatus) => {
  useQuery('status', async () => {
    return await api.status();
  }, {
    retry: 0,
    onSuccess: (response) => {
      checkAccess(true, response.data.isPro, featureStatus);
    },
    onError: (err) => {
      checkAccess(false, false, featureStatus);
    }
  })
}

const LetsGetYouVerifiedPage = () => {
  const { featureStatus } = React.useContext(GlobalStoreContext);
  CheckFreeUserAccess(featureStatus);

  const [isMobileValid, setIsMobileValid] = React.useState(null)
  const [mobile, setMobile] = React.useState('')
  const [isMakingRequest, setIsMakingRequest] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [isLoading, setIsloading] = React.useState(false)

  const handleChange = (e) => {
    const srcval = e.target.value
    setMobile(srcval)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      if (isMobileValid) {
        requestOTPToken()
      }
    }
  }

  const requestOTPToken = () => {
    setErrorMessage(null)
    setIsMakingRequest(true)
    setIsloading(true)
    api.sendOTP(mobile).then(() => {
      setIsMakingRequest(false)
      navTo.letsgetyouverifiedotp_4({ mobile })
      setIsloading(false)
    }).catch(() => {
      setIsloading(false)
      setIsMakingRequest(false)
      setErrorMessage("Request One-Time Password error, please try again")
    })
  }

  React.useEffect(() => {
    if (mobile && mobile.length === 10) {
      const valid = mobile.match(mobileRegexPattern)
      if (valid) {
        setIsMobileValid(true)
      } else {
        setIsMobileValid(false)
      }
    } else {
      setIsMobileValid(null)
    }
  }, [mobile])

  const EnterMobileNumberSection = () => (<>
    <Text.InputFieldHeader style={{ paddingBottom: '10px', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }} aria-label="Mobile number">Mobile number</Text.InputFieldHeader>
    <MobileNumberField autoFocus mobileValue={mobile} onChange={handleChange} status={isMobileValid === null ? null : isMobileValid === true ? 'success' : 'failed'} onKeyDown={handleKeyDown} />
    <Text.HelperText style={{ paddingTop: '10px', color: '#373737', fontFamily: 'Arial' }}>
      {isMobileValid === false ? 'Your mobile number looks not right. Try again' : 'Please enter your mobile number'}</Text.HelperText>
  </>)

  const ErrorMessageSection = () => (<>
    {errorMessage &&
      <ErrorBootstrapSection className="bootstrap" style={{ marginBottom: '0px' }}>
        <div className="alert alert-danger">{errorMessage}</div>
      </ErrorBootstrapSection>
    }
  </>)

  const InfoSection = () => (<Flex>
    <div><InfoIcon alt='' src={InfoIconSvg} /></div>
    <Text.HelperText style={{ paddingLeft: '15px' }}>Your mobile number will help us to identify you later, if you wish to visit your consent dashboard</Text.HelperText>
  </Flex>)


  const ButtonGroup = () => {
    return (
      <Buttons.BackNextButtons
        backOnClick={() => ButtonDelay(navTo.howitworks_2)}
        nextOnClick={requestOTPToken}
        nextDisabled={!isMobileValid || isMakingRequest}
        isLoading={isLoading}
      />)
  }

  const LetGetVerify = "Let’s get you verified"
  const subText = "We’ll use your mobile number to verify you via a one-time password"

  return (
    <Layout.ColumnLayout fitToHeightOnMobile={true} title={LetGetVerify}>
      <HeaderBanner />


      {/* mobile only */}
      <Vis.MobileOnly flex="col">
        <BodyWrapper flex="col nowgrow">
          <WhiteSection flex="col nogrow" style={{ margin: '29px 10px 0px 10px' }}>
            <Text.H1 style={{ marginTop: '0px', marginBottom: '16px', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{LetGetVerify}</Text.H1>
            <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>{subText}</GreenBarSection>
          </WhiteSection>
          <WhiteSection flex="col nogrow">
            <EnterMobileNumberSection />
          </WhiteSection>

          <ErrorMessageSection />
        </BodyWrapper>
        <WhiteSection flex="row nogrow" style={{ justifyContent: 'center', margin: '0px 0px 0px 0px' }}>
          {/* <ButtonGroup /> */}
          <ButtonLinkOutline
            style={{ padding: "0 30px", marginRight: '21px', minWidth: "50%" }}
            rel="noreferrer noopener"
            onClick={() => ButtonDelay(navTo.howitworks_2)}
          >
            Back
          </ButtonLinkOutline>
          <ButtonGreen
            style={{ padding: "0 30px", minWidth: "50%" }}
            onClick={requestOTPToken}
            disabled={(!isMobileValid || isMakingRequest) || isLoading}
            isLoading={isLoading}>
            Next
          </ButtonGreen>
        </WhiteSection>

      </Vis.MobileOnly>

      {/* Tablet only */}
      <Vis.TabletOnly flex="col">
        <WhiteSection flex="col nogrow" style={{ margin: '0px 35px 0px 38px' }}>
          <Text.H1 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{LetGetVerify}</Text.H1>
          <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>{subText}</GreenBarSection>
        </WhiteSection>
        <LightBlueSection flex="col hcenter nogrow" style={{ margin: '0px 70px', padding: '50px 20px' }}>
          <Flex flex="col" style={{ width: '420px' }}>
            <EnterMobileNumberSection />

            <ErrorMessageSection />

            <Flex flex="row nogrow" style={{ marginTop: '25px', padding: '15px 0 80px 0px' }}>
              {/* <InfoSection /> */}
            </Flex>
          </Flex>
        </LightBlueSection>
        {/* fit to full page height on mobile */}
        <Flex />
        <WhiteSection flex="row nogrow" style={{ justifyContent: 'center', margin: '40px 0px' }}>
          {/* <ButtonGroup /> */}
          <ButtonLinkOutline
            style={{ padding: "0 30px", marginRight: '21px', minWidth: "50%" }}
            rel="noreferrer noopener"
            onClick={() => ButtonDelay(navTo.howitworks_2)}
          >
            Back
          </ButtonLinkOutline>
          <ButtonGreen
            style={{ padding: "0 30px", minWidth: "50%" }}
            onClick={requestOTPToken}
            disabled={(!isMobileValid || isMakingRequest) || isLoading}
            isLoading={isLoading}>
            Next
          </ButtonGreen>
        </WhiteSection>
      </Vis.TabletOnly>

      {/* Desktop only */}
      <Vis.DesktopOnly flex="col">
        <WhiteSection flex="row" style={{ margin: '52px 100px 0px 100px' }}>
          <WhiteSection flex="col w50" style={{ padding: '0px' }}>
            <WhiteSection flex="col nogrow">
              <Text.H1 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{LetGetVerify}</Text.H1>
              <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial', maxWidth: '480px' }}>{subText}</GreenBarSection>
            </WhiteSection>
            <WhiteSection flex="row" style={{ marginTop: "82px", padding: "0px 0px", width: '424px' }}>
              {/* <ButtonGroup /> */}
              <ButtonLinkOutline
                style={{ padding: "0 30px", marginRight: '21px', minWidth: "200px" }}
                rel="noreferrer noopener"
                onClick={() => ButtonDelay(navTo.howitworks_2)}
              >
                Back
              </ButtonLinkOutline>
              <ButtonGreen
                style={{ padding: "0 30px", marginRight: '21px', minWidth: "200px" }}
                onClick={requestOTPToken}
                disabled={(!isMobileValid || isMakingRequest) || isLoading}
                isLoading={isLoading}>
                Next
              </ButtonGreen>
            </WhiteSection>
          </WhiteSection>
          <Flex flex="w50 col" style={{ paddingTop: '35px' }}>
            <LightBlueSection flex="col hcenter nogrow" style={{ padding: '50px 0 150px 0px' }}>
              <Flex flex="col" style={{ width: '375px' }}>
                <EnterMobileNumberSection />

                <ErrorMessageSection />

                <Flex flex="row nogrow" style={{ marginTop: '70px', padding: '10px' }}>
                  {/* <InfoSection /> */}
                </Flex>
              </Flex>
            </LightBlueSection>
          </Flex>

        </WhiteSection>
      </Vis.DesktopOnly>


    </Layout.ColumnLayout>
  )
}

export default LetsGetYouVerifiedPage
